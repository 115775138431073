/* eslint-disable react/no-array-index-key */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { SliderComponent as SourceSliderComponent } from 'SourceComponent/Slider/Slider.component';

import './Slider.override.style';

/**
 * Homepage slider
 * @class SliderWidget
 * @namespace Poprawa/Component/Slider/Component */
export class SliderComponent extends SourceSliderComponent {

}

export default SliderComponent;

/* eslint-disable react/no-array-index-key */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { SliderWidgetComponent as SourceSliderWidgetComponent } from 'SourceComponent/SliderWidget/SliderWidget.component';

import './SliderWidget.override.style';

/**
 * Homepage slider
 * @class SliderWidget
 * @namespace Poprawa/Component/SliderWidget/Component */
export class SliderWidgetComponent extends SourceSliderWidgetComponent {

}

export default SliderWidgetComponent;

/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { ReactComponent as ChevronIcon } from 'assets/icons/chevron.svg';

import { ChevronIconComponent as SourceChevronIconComponent } from 'SourceComponent/ChevronIcon/ChevronIcon.component';

import './ChevronIcon.override.style';

/** @namespace Poprawa/Component/ChevronIcon/Component */
export class ChevronIconComponent extends SourceChevronIconComponent {
    render() {
        const { direction } = this.props;

        const props: {className?: string } = {
            className: 'ChevronIcon',
        };

        if (direction) {
            props.className = `ChevronIcon ChevronIcon_direction_${direction}`;
        }

        return <ChevronIcon { ...props } />;
    }
}

export default ChevronIconComponent;
